import { combineReducers } from '@reduxjs/toolkit';
import clientFilterReducer from './clientFilter/slice';
import meetingFilterReducer from './meetingFilter/slice';
import messageSessionsReducer from './messaging/messageSessions/slice';
import messagingRepository from './messaging/repository/slice';
import providerFilterReducer from './providerFilter/slice';
import sessionReducer from './session/slice';

const rootReducer = combineReducers({
  clientFilter: clientFilterReducer,
  meetingFilter: meetingFilterReducer,
  messageSessions: messageSessionsReducer,
  messagingRepository,
  providerFilter: providerFilterReducer,
  session: sessionReducer
});

export default rootReducer;
