import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initialSessionState } from './types';

export const sessionSlice = createSlice({
  initialState: initialSessionState,
  name: 'session',
  reducers: {
    updateLanguage: (draft, action: PayloadAction<string>) => {
      draft.settings.language = action.payload;
    }
  }
});

export default sessionSlice.reducer;

export const { updateLanguage } = sessionSlice.actions;
