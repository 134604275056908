import { ClientFilter, initialClientFilter, initialClientFilterState } from './types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const clientFilterSlice = createSlice({
  initialState: initialClientFilterState,
  name: 'clientFilter',
  reducers: {
    clearFilter: (draft) => {
      draft.clientFilter = initialClientFilter;
    },
    removeFilterItem: (draft, action: PayloadAction<string>) => {
      const key = action.payload as keyof ClientFilter;

      draft.clientFilter[key] = initialClientFilter[key] as any;
    },
    removeUserState: (draft, action: PayloadAction<number>) => {
      draft.clientFilter.userState.splice(action.payload, 1);
    },
    updateFilter: (draft, action: PayloadAction<ClientFilter>) => {
      draft.clientFilter = { ...initialClientFilter, ...action.payload };
    }
  }
});

export default clientFilterSlice.reducer;
export const { clearFilter, removeFilterItem, removeUserState, updateFilter } =
  clientFilterSlice.actions;
