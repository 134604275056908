import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export type ColorSchema = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    darker: string;
    lighter: string;
  }
  interface PaletteColor {
    darker: string;
    lighter: string;
  }
}

// SETUP COLORS

export const grey = {
  100: '#f7faf9',
  200: '#f2f7f6',
  300: '#e3e8e7',
  400: '#ccd6d5',
  500: '#9caba9',
  600: '#6e827f',
  700: '#435c59',
  800: '#193631',
  900: '#112420'
};

export const primary = {
  contrastText: '#FFFFFF',
  dark: '#007867',
  darker: '#004b50',
  light: '#5be49b',
  lighter: '#c8fad6',
  main: '#009874'
};

export const secondary = {
  contrastText: '#FFFFFF',
  dark: '#4146a3',
  darker: '#282c66',
  light: '#adb1f7',
  lighter: '#d4d7fa',
  main: '#5960df'
};

export const info = {
  contrastText: '#FFFFFF',
  dark: '#006c9c',
  darker: '#003768',
  light: '#61f3f3',
  lighter: '#cafdf5',
  main: '#00b8d9'
};

export const success = {
  contrastText: '#FFFFFF',
  dark: '#118d57',
  darker: '#065e49',
  light: '#77ed8b',
  lighter: '#d3fcd2',
  main: '#11c854'
};

export const warning = {
  contrastText: grey[800],
  dark: '#b76e00',
  darker: '#7a4100',
  light: '#ffd666',
  lighter: '#fff5cc',
  main: '#ffab00'
};

export const error = {
  contrastText: '#FFFFFF',
  dark: '#b71d18',
  darker: '#7a0916',
  light: '#ffbdad',
  lighter: '#ffebe6',
  main: '#e43d18'
};

export const common = {
  black: '#000000',
  white: '#FFFFFF'
};

export const action = {
  disabled: alpha(grey[500], 0.8),
  disabledBackground: alpha(grey[500], 0.24),
  disabledOpacity: 0.48,
  focus: alpha(grey[500], 0.24),
  hover: alpha(grey[500], 0.08),
  hoverOpacity: 0.08,
  selected: alpha(grey[500], 0.16)
};

const base = {
  action,
  common,
  divider: alpha(grey[500], 0.2),
  error,
  grey,
  info,
  primary,
  secondary,
  success,
  warning
};

// ----------------------------------------------------------------------

export function palette(mode: 'light' | 'dark') {
  const light = {
    ...base,
    action: {
      ...base.action,
      active: grey[600]
    },
    background: {
      default: '#FFFFFF',
      neutral: grey[200],
      paper: '#FFFFFF'
    },
    mode: 'light',
    text: {
      disabled: grey[500],
      primary: grey[800],
      secondary: grey[600]
    }
  };

  const dark = {
    ...base,
    action: {
      ...base.action,
      active: grey[500]
    },
    background: {
      default: grey[900],
      neutral: alpha(grey[500], 0.12),
      paper: grey[800]
    },
    mode: 'dark',
    text: {
      disabled: grey[600],
      primary: '#FFFFFF',
      secondary: grey[500]
    }
  };

  return mode === 'light' ? light : dark;
}
