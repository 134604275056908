import { Theme, alpha } from '@mui/material/styles';
import { buttonClasses } from '@mui/material/Button';
import { circularProgressClasses } from '@mui/material/CircularProgress';
import { formControlClasses } from '@mui/material/FormControl';
import { iconButtonClasses } from '@mui/material/IconButton';
import { inputLabelClasses } from '@mui/material/InputLabel';
import { listClasses } from '@mui/material/List';
import { listItemIconClasses } from '@mui/material/ListItemIcon';
import { paper } from '../../css';
import { paperClasses } from '@mui/material/Paper';

// ----------------------------------------------------------------------

export function dataGrid(theme: Theme) {
  const paperStyles = paper({ dropdown: true, theme });

  return {
    MuiDataGrid: {
      styleOverrides: {
        // Row, Cell
        cell: {
          '&--editing': {
            backgroundColor: `${alpha(theme.palette.primary.main, 0.08)} !important`,
            boxShadow: 'none !important'
          },
          borderBottom: `1px dashed`
        },

        columnHeader: {
          '&--sorted': {
            color: theme.palette.text.primary
          },
          backgroundColor: theme.palette.background.neutral,
          color: theme.palette.text.secondary,
          fontSize: 14
        },

        // Column
        columnHeaders: {
          borderBottom: 0
        },

        columnSeparator: {
          color: theme.palette.divider
        },

        columnsPanelRow: {
          margin: theme.spacing(0.5, 0)
        },

        filterForm: {
          alignItems: 'center',
          gap: theme.spacing(1.5),
          padding: theme.spacing(1)
        },

        filterFormValueInput: {
          [`& .${formControlClasses.root}`]: {
            width: '100%'
          },
          [`& .${inputLabelClasses.root}`]: {
            transform: 'translate(14px, -9px) scale(0.75)'
          }
        },

        // Footer
        footerContainer: {
          borderTop: `1px dashed`,
          minHeight: 'auto'
        },

        iconButtonContainer: {
          [`& .${iconButtonClasses.root}`]: {
            marginLeft: theme.spacing(1),
            padding: theme.spacing(0.25)
          }
        },

        menu: {
          [`& .${paperClasses.root}`]: {
            ...paperStyles,
            minWidth: 140
          },
          [`& .${listClasses.root}`]: {
            padding: 0,
            [`& .${listItemIconClasses.root}`]: {
              marginRight: theme.spacing(2),
              minWidth: 0
            }
          }
        },

        // Icons
        menuIcon: {
          [`& .${iconButtonClasses.root}`]: {
            margin: theme.spacing(0, 1),
            padding: theme.spacing(0.25)
          }
        },

        overlay: {
          [`& .${circularProgressClasses.root}`]: {
            color: theme.palette.text.primary
          }
        },

        panelContent: {
          padding: theme.spacing(1)
        },

        panelFooter: {
          borderTop: `dashed 1px ${theme.palette.divider}`,
          display: 'none',
          gap: theme.spacing(1),
          justifyContent: 'flex-end',
          padding: theme.spacing(2),
          [`& .${buttonClasses.root}`]: {
            '&:first-of-type': {
              border: `solid 1px ${alpha(theme.palette.grey[500], 0.24)}`
            },
            '&:last-of-type': {
              backgroundColor: theme.palette.text.primary,
              color: theme.palette.background.paper
            },
            padding: theme.spacing(0.5, 1.5)
          }
        },

        // Columns Panel
        panelHeader: {
          padding: theme.spacing(2, 2, 0, 2)
        },

        // Paper
        paper: {
          ...paperStyles,
          padding: 0
        },

        root: {
          '--unstable_DataGrid-headWeight': theme.typography.fontWeightSemiBold,
          '--unstable_DataGrid-radius': 0,
          borderWidth: 0
        },

        selectedRowCount: {
          display: 'none',
          whiteSpace: 'nowrap'
        },

        // Toolbar
        toolbarContainer: {
          gap: theme.spacing(2),
          padding: theme.spacing(2)
        },

        toolbarQuickFilter: {
          padding: 0,
          width: '100%',
          [theme.breakpoints.up('md')]: {
            width: 'unset'
          }
        },

        withBorderColor: {
          borderColor: theme.palette.divider
        }
      }
    }
  };
}
