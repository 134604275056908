export type MeetingListItem = {
  createdAt: string;
  creditApproved: boolean;
  deleteReason: string;
  deletedAt: string;
  durationSeconds: number;
  endDate: string;
  hasProviderFeedback: boolean;
  id: string;
  meetingHeld: boolean;
  participantIds: string[];
  participants: Participant[];
  paymentClearedAt: string;
  providerEarned: boolean;
  revenueInfo: RevenueInfo;
  startDate: string;
  type: string;
};

export type RevenueInfo = {
  compensationDetails: CompensationDetail[];
  compensationType: string;
  planAlias: string;
  priceRevenue: number;
  salePriceRevenue: number;
  sponsorId: string;
  sponsorshipId: string;
  sponsorshipType: string;
  verticalPlanAlias: string;
};

export type CompensationDetail = {
  checkoutId: string;
  creditType: string;
  creditUsed: number;
  meetingRightType: string;
  priceRevenue: number;
  salePriceRevenue: number;
  sku: string;
  transactionId: string;
};

export type Participant = {
  email: string;
  firstname: string;
  id: string;
  lastname: string;
  profilePictureUrl: string;
  title: string;
  userState: string;
  userType: string;
};

export const providerEarnTypes = [
  'MeetingHeld',
  'OnlyPreCall',
  'NoPreCall',
  'ClientCancelLate',
  'ClientCancelEarly',
  'ProviderCancel',
  'BackofficeCancel',
  'ClientDismissedProviderLate',
  'ClientDismissedProviderEarly',
  'ClientDeletedAccountLate',
  'ClientDeletedAccountEarly',
  'InsufficientFunds',
  'BackofficeOverride',
  'FeedbackNotSubmittedInTime'
] as const;

export const meetingTypes = [
  'PreAppointment',
  'Appointment',
  'FollowUpSession',
  'Extension',
  'IntroSession',
  'PhysicalSession'
] as const;

export type MeetingType = (typeof meetingTypes)[number];

export type IssueType = (typeof providerEarnTypes)[number];
