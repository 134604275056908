import { AuthGuard } from 'src/auth/guard';
import { Outlet } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import Redirecting from '../components/redirecting';

const NotFoundView = lazy(() => import('src/views/error/NotFoundView'));
const DashboardPage = lazy(() => import('src/views/dashboard/DashboardPage'));

const ClientListPage = lazy(() => import('src/views/client/list/ClientListPage'));
const ClientDetailPage = lazy(() => import('src/views/client/detail/ClientDetailPage'));

const ProviderListPage = lazy(() => import('src/views/provider/list/ProviderListPage'));
const ProviderDetailPage = lazy(() => import('src/views/provider/detail/ProviderDetailPage'));
const PricingGroupListPage = lazy(
  () => import('src/views/provider/pricingGroup/ProviderPricingGroupListPage')
);
const ProfessionalGroupListPage = lazy(
  () => import('src/views/provider/professionalGroup/list/ProfessionalGroupListPage')
);
const ProfessionalGroupDetailPage = lazy(
  () => import('src/views/provider/professionalGroup/detail/ProfessionalGroupDetailPage')
);

const ChatSessionListPage = lazy(() => import('src/views/messages/list/MessagesListPage'));

const MeetingListPage = lazy(() => import('src/views/meeting/list/MeetingListPage'));
const MeetingDetailPage = lazy(() => import('src/views/meeting/detail/MeetingDetailPage'));
const MeetingPackageOptionsListPage = lazy(
  () => import('src/views/plan/packageOptions/list/MeetingPackageOptionsListPage')
);
const MeetingPackageOptionDetailPage = lazy(
  () => import('src/views/plan/packageOptions/detail/MeetingPackageOptionDetailPage')
);
const MeetingPackageOptionCreatePage = lazy(
  () => import('src/views/plan/packageOptions/detail/MeetingPackageOptionCreatePage')
);

const UserPlanPrototypeListPage = lazy(
  () => import('src/views/plan/userPlanPrototypes/list/UserPlanPrototypesListPage')
);
const UserPlanPrototypeDetail = lazy(
  () => import('src/views/plan/userPlanPrototypes/detail/UserPlanPrototypeDetail')
);
const UserPlanPrototypeCreatePage = lazy(
  () => import('src/views/plan/userPlanPrototypes/detail/UserPlanPrototypeCreatePage')
);

const VerticalPlanPrototypesListPage = lazy(
  () => import('src/views/plan/verticalPlanPrototypes/list/VerticalPlanPrototypesListPage')
);
const VerticalPlanPrototypeDetailPage = lazy(
  () => import('src/views/plan/verticalPlanPrototypes/detail/VerticalPlanPrototypeDetailPage')
);
const VerticalPlanPrototypeCreatePage = lazy(
  () => import('src/views/plan/verticalPlanPrototypes/detail/VerticalPlanPrototypeCreatePage')
);

const SponsorListPage = lazy(() => import('src/views/sponsor/list/SponsorListPage'));
const SponsorDetailPage = lazy(() => import('src/views/sponsor/detail/SponsorDetailPage'));

const EventListPage = lazy(() => import('src/views/event/list/EventListPage'));
const EventDetailPage = lazy(() => import('src/views/event/detail/EventDetailPage'));
const CreateEventPage = lazy(() => import('src/views/event/modify/CreateEventPage'));

export const kerberosRoutes = [
  {
    children: [
      { element: <DashboardPage />, path: 'dashboard' },
      {
        children: [
          { element: <ClientListPage />, index: true, path: 'list' },
          { element: <ClientDetailPage />, path: ':id' }
        ],
        path: 'client'
      },
      {
        children: [
          { element: <ProviderListPage />, index: true, path: 'list' },
          { element: <ProviderDetailPage />, path: ':id' },
          { element: <PricingGroupListPage />, path: 'pricing-groups' },
          { element: <ProfessionalGroupListPage />, path: 'professional-groups/list' },
          { element: <ProfessionalGroupDetailPage />, path: 'professional-groups/:id' }
        ],
        path: 'provider'
      },
      {
        children: [
          { element: <ChatSessionListPage />, index: true },
          { element: <ChatSessionListPage />, path: 'list' }
        ],
        path: 'messages'
      },
      {
        children: [
          { element: <MeetingListPage />, index: true, path: 'list' },
          { element: <MeetingDetailPage />, path: ':id' }
        ],
        path: 'meetings'
      },
      {
        children: [
          {
            element: <MeetingPackageOptionsListPage />,
            index: true,
            path: 'meeting-package-options/list'
          },
          {
            element: <MeetingPackageOptionCreatePage />,
            path: 'meeting-package-options/create'
          },
          {
            element: <MeetingPackageOptionDetailPage />,
            path: 'meeting-package-options/:id'
          },
          {
            element: <UserPlanPrototypeListPage />,
            path: 'user-plan-prototypes/list'
          },
          {
            element: <UserPlanPrototypeDetail />,
            path: 'user-plan-prototypes/:id'
          },
          {
            element: <UserPlanPrototypeCreatePage />,
            path: 'user-plan-prototypes/create'
          },
          {
            element: <VerticalPlanPrototypesListPage />,
            path: 'vertical-plan-prototypes/list'
          },
          {
            element: <VerticalPlanPrototypeCreatePage />,
            path: 'vertical-plan-prototypes/create'
          },
          {
            element: <VerticalPlanPrototypeDetailPage />,
            path: 'vertical-plan-prototypes/:id'
          }
        ],
        path: 'plan'
      },
      {
        children: [
          { element: <SponsorListPage />, index: true, path: 'list' },
          { element: <SponsorDetailPage />, path: ':id' }
        ],
        path: 'sponsor'
      },
      {
        children: [
          { element: <EventListPage />, index: true, path: 'list' },
          { element: <EventDetailPage />, path: ':id' },
          { element: <CreateEventPage />, path: 'create' }
        ],
        path: 'event'
      }
    ],
    element: (
      <AuthGuard>
        <Suspense fallback={<Redirecting />}>
          <Outlet />
        </Suspense>
      </AuthGuard>
    )
  },
  {
    children: [{ element: <NotFoundView />, path: '404' }],
    element: (
      <Suspense fallback={<Redirecting />}>
        <Outlet />
      </Suspense>
    )
  }
];
