import { AxiosError } from 'axios';
import { QueryCache, QueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'src/components/snackbar';
import { useMemo } from 'react';

export const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      onError: (error) => createErrorSnackbar(error)
    },
    queries: {
      retry: (failureCount: number, error: any) => {
        if (isAxiosError(error) && error.response?.status === 401) {
          return false; // Stop retrying on 401 errors
        }

        const shouldRetry = failureCount < 1;
        return shouldRetry;
      },
      retryDelay: 1000
    }
  },

  queryCache: new QueryCache({
    onError: (error) => createErrorSnackbar(error)
  })
});
const isAxiosError = (error: any): error is AxiosError => error.isAxiosError === true;

const createErrorSnackbar = (error: any) => {
  const errorMessage = getErrorMessage(error);
  enqueueSnackbar(errorMessage, { variant: 'error' });
};

const getErrorMessage = (error: any) => error.response?.data?.message ?? error.message;

function useDistinctItems<T>(items: T[], isSuccess: boolean, id: keyof T) {
  const distinctItems = useMemo(() => {
    if (!isSuccess) {
      return [];
    }

    return [...new Map(items.map((item) => [item[id], item])).values()];
  }, [items, id, isSuccess]);

  return distinctItems;
}

export function useQueryItems<T>(data: T[], isSuccess: boolean, id: keyof T) {
  const items = useDistinctItems(data, isSuccess, id);
  const total = isSuccess ? data.length : 0;

  return { items, total };
}
