import { MeetingFilter, initialMeetingFilter, initialMeetingFilterState } from './types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const meetingFilterSlice = createSlice({
  initialState: initialMeetingFilterState,
  name: 'meetingFilter',
  reducers: {
    removeClientId: (draft) => {
      draft.meetingFilter.clientId = initialMeetingFilter.clientId;
    },
    removeHasProviderFeedback: (draft) => {
      draft.meetingFilter.hasProviderFeedback = initialMeetingFilter.hasProviderFeedback;
    },
    removeId: (draft) => {
      draft.meetingFilter.id = initialMeetingFilter.id;
    },
    removeMeetingHeld: (draft) => {
      draft.meetingFilter.meetingHeld = initialMeetingFilter.meetingHeld;
    },
    removeMeetingType: (draft) => {
      draft.meetingFilter.meetingType = initialMeetingFilter.meetingType;
    },
    removeProviderEarnType: (draft, action: PayloadAction<number>) => {
      draft.meetingFilter.providerEarnType.splice(action.payload, 1);
    },
    removeProviderEarned: (draft) => {
      draft.meetingFilter.providerEarned = initialMeetingFilter.providerEarned;
    },
    removeProviderId: (draft) => {
      draft.meetingFilter.providerId = initialMeetingFilter.providerId;
    },
    removeSponsorshipType: (draft) => {
      draft.meetingFilter.sponsorshipType = initialMeetingFilter.sponsorshipType;
    },
    removeWindowEnd: (draft) => {
      draft.meetingFilter.windowEnd = initialMeetingFilter.windowEnd;
    },
    removeWindowStart: (draft) => {
      draft.meetingFilter.windowStart = initialMeetingFilter.windowStart;
    },
    updateFilter: (draft, action: PayloadAction<MeetingFilter>) => {
      draft.meetingFilter = { ...initialMeetingFilter, ...action.payload };
    }
  }
});

export default meetingFilterSlice.reducer;
export const {
  removeClientId,
  removeHasProviderFeedback,
  removeId,
  removeMeetingHeld,
  removeMeetingType,
  removeProviderEarnType,
  removeProviderEarned,
  removeProviderId,
  removeSponsorshipType,
  removeWindowEnd,
  removeWindowStart,
  updateFilter
} = meetingFilterSlice.actions;
