import * as signalR from '@microsoft/signalr';
import { USER_AUTH_TOKENS_KEY } from 'src/auth/context/utils';
import { UserAuthTokens } from 'src/auth/types';
import { UserIssueGroup } from 'src/store/messaging/types';
import { endpoints } from 'src/utils/axios';
import LocalStorageUtil from 'src/utils/localStorage';

const bearerToken = LocalStorageUtil.getItem<UserAuthTokens>(USER_AUTH_TOKENS_KEY);
const apiBaseUrl = import.meta.env.VITE_API_BASE_URL;

class Connector {
  private connection: signalR.HubConnection;

  public events: (onIssueGroupUpdated: (userIssueGroup: UserIssueGroup) => void) => void;

  static instance: Connector;

  constructor() {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(`${apiBaseUrl}${endpoints.messagingHub}`, {
        accessTokenFactory: () => bearerToken?.token ?? ''
      })
      .withAutomaticReconnect()
      .build();
    if (bearerToken?.token != null || bearerToken?.token !== undefined) {
      this.connection.start().catch((err) => document.write(err));
    }
    this.events = (onSessionIssue) => {
      this.connection.on('IssueUpdated', (Issue) => {
        onSessionIssue(Issue);
      });
    };
  }

  public static getInstance(): Connector {
    if (!Connector.instance) Connector.instance = new Connector();
    return Connector.instance;
  }
}
export default Connector.getInstance;
