import { Navigate, useRoutes } from 'react-router-dom';
import { kerberosRoutes } from './kerberos';
import { loginRoutes } from './login';
import { paths } from '../paths';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // SET INDEX PAGE WITH SKIP HOME PAGE
    {
      element: <Navigate replace to={paths.dashboard.root} />,
      path: '/'
    },
    ...loginRoutes,
    ...kerberosRoutes,
    { element: <Navigate replace to="/404" />, path: '*' }
  ]);
}
