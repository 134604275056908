import * as yup from 'yup';
import { initialClientFilter } from 'src/store/clientFilter/types';
import { selectClientFilter } from 'src/store/clientFilter/selector';
import { sponsorshipTypes } from '../types';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTypedSelector } from 'src/hooks';
import { yupResolver } from '@hookform/resolvers/yup';

export const clientFilterSchema = yup.object().shape({
  email: yup.string(),
  firstname: yup.string(),
  lastname: yup.string(),
  planAlias: yup.string(),
  planType: yup
    .string()
    .nullable()
    .oneOf([...sponsorshipTypes, '']),
  sponsorId: yup.string(),
  sponsorshipId: yup.string(),
  userState: yup.array().of(yup.string())
});

export const useFilterForm = () => {
  const storeClientFilter = useTypedSelector(selectClientFilter);
  const formMethods = useForm({
    defaultValues: initialClientFilter,
    mode: 'all',
    resolver: yupResolver(clientFilterSchema)
  });

  const { reset } = formMethods;

  useEffect(() => {
    reset(storeClientFilter);
  }, [reset, storeClientFilter]);

  return formMethods;
};
