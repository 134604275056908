export const sponsorshipTypes: SponsorshipType[] = ['B2B', 'B2C'];
export type SponsorshipType = 'B2B' | 'B2C' | '';

export type ClientListItem = {
  createdAt: string;
  email: string;
  enrolledVerticals: string[];
  firstname: string;
  id: string;
  lastname: string;
  planType?: SponsorshipType;
  supportChatSessionId?: string;
};
