import './App.css';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { AuthProvider } from './auth/context';
import { HelmetProvider } from 'react-helmet-async';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SnackbarProvider } from './components/snackbar';
import { Provider as StoreProvider } from 'react-redux';
import { queryClient } from './utils/react-query';
import { tr } from 'date-fns/locale';
import MessagingRepositoryProvider from './store/messaging/repository/provider';
import Router from './routes/sections';
import ThemeProvider from './theme';
import store from './store';

function App() {
  return (
    <HelmetProvider>
      <SnackbarProvider>
        <AuthProvider>
          <StoreProvider store={store}>
            <QueryClientProvider client={queryClient}>
              <MessagingRepositoryProvider>
                <ThemeProvider>
                  <LocalizationProvider adapterLocale={tr} dateAdapter={AdapterDateFns}>
                    <ReactQueryDevtools buttonPosition="bottom-left" />
                    <Router />
                  </LocalizationProvider>
                </ThemeProvider>
              </MessagingRepositoryProvider>
            </QueryClientProvider>
          </StoreProvider>
        </AuthProvider>
      </SnackbarProvider>
    </HelmetProvider>
  );
}

export default App;
