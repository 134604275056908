import { AuthUserType } from 'src/auth/types';
import { UserType } from 'src/common/models';

export interface UserIssueGroup {
  chatSession: ChatSession;
  chatSessionId: string;
  lastUpdatedAt: Date;
  operator: UserSummary;
  operatorId: string;
  processingSince: Date;
  relatedIssues: Issue[];
  user: UserSummary;
  userId: string;
}
export interface Issue {
  actionTaken: ActionTaken;
  careTeamLogId: string;
  chatSession: ChatSession;
  chatSessionId: string;
  createType: IssueCreatorType;
  createdAt: Date;
  description: string;
  entityType: IssueEntityType;
  firstMessageDateByOperator: Date;
  firstMessageDateByUser: Date;
  id: string;
  initialReason: IssueInitialReason;
  lastMessageDateByOperator: Date;
  lastMessageDateByUser: Date;
  lastUpdatedDate: Date;
  meetingId: string;
  mergedIssueIds: string[];
  mergedToAnotherIssue: boolean;
  operator: UserSummary;
  operatorExplanation: string;
  operatorId: string;
  parentIssueId: string;
  state: IssueStatus;
  type: IssueType;
  user: UserSummary;
  userId: string;
}
export type IssueStatus = 'Potential' | 'Open' | 'Processing' | 'Closed';

export type IssueInitialMedium = 'Chat' | 'MobileClient' | 'WebClient' | 'Email' | 'Backoffice';

export type IssueCreatorType = 'User' | 'Operator' | 'System';
export type IssueInitialReason = 'General' | 'ProviderEarningObjection';

export enum IssueEntityType {
  CareTeamLog = 'CareTeamLog',
  ChatSession = 'ChatSession',
  Meeting = 'Meeting'
}

export const actionTakenArray = ['ActionTaken', 'NoActionTaken', 'Informed'] as const;
export type ActionTaken = (typeof actionTakenArray)[number];

export const issueTypes = [
  'BasicQuestions',
  'Credit',
  'MatchingExperience',
  'Meeting',
  'MeetingDispute',
  'Other',
  'Plan',
  'ProviderProblems',
  'TechnicalProblems'
] as const;

export type IssueType = (typeof issueTypes)[number];

export interface ChatSession {
  actionLogs: MessageActionLog[];
  createdAt: Date;
  id: string;
  lastMessage: Message;
  lastMessageDate: Date;
  messageCount: number;
  participants: UserSummary[];
  unreadMessageCount: number;
}

export interface UserSummary {
  email: string;
  firstname: string;
  id: string;
  lastname: string;
  profilePictureUrl: string;
  state: UserState;
  title: string;
  type: string;
  userType: UserType;
}

export interface Message {
  actionLogs: MessageActionLog[];
  attachments: Attachment[];
  author: string;
  dateReceived: Date;
  dateSent: Date;
  id: string;
  sentBy: string;
  sessionID: string;
  text: string;
}

export interface MessageActionLog {
  action: MessageAction;
  date: Date;
  userId: string;
}

export enum MessageAction {
  Read,
  Unread,
  Sent,
  Received
}

export enum AttachmentType {
  File = 'File',
  Image = 'Image'
}

export interface Attachment {
  id: string;
  mediaType: string;
  name: string;
  thumbnailId: string;
  thumbnailUrl: string;
  type: AttachmentType;
  url: string;
}

export type UserState =
  | 'SignedUp'
  | 'Verified'
  | 'NotSponsored'
  | 'Activated'
  | 'Onboarded'
  | 'Deactivated'
  | 'Deleted'
  | 'OnboardingStarted'
  | 'InitialVerticalSelected';

export const initialIssues: UserIssueGroup[] = [];
export const initialIssueGroupFilter: IssueGroupFilter = {
  IncludeEntityTypes: [
    IssueEntityType.ChatSession,
    IssueEntityType.Meeting,
    IssueEntityType.CareTeamLog
  ],
  chatSessionId: null,
  includeNotOwned: true,
  includeUnassigned: true,
  limit: 150,
  userId: null
};

export interface IssueGroupFilter {
  IncludeEntityTypes: IssueEntityType[];
  chatSessionId: string | null;
  includeNotOwned: boolean;
  includeUnassigned: boolean;
  limit: number;
  userId: string | null;
}

export interface MessagesState {
  activeFilter: IssueGroupFilter;
  activeIssueGroup: UserIssueGroup;
  isFetching: boolean;
  issueGroups: UserIssueGroup[];
  lastUpdated: Date;
  messages: Message[];
  needsAttention: number;
}

export const initialMessagesState: MessagesState = {
  activeFilter: initialIssueGroupFilter,
  activeIssueGroup: null,
  isFetching: true,
  issueGroups: initialIssues,
  lastUpdated: null,
  messages: [],
  needsAttention: 0
};

export interface MessagingRepoState {
  isFetching: boolean;
  issueGroups: UserIssueGroup[];
  lastUpdated: Date;
  sessionMessages: Map<string, Message[]>;
  urlSessionInitialized: boolean;
  user: AuthUserType | null;
}

export const initialMessagingRepoState: MessagingRepoState = {
  isFetching: true,
  issueGroups: initialIssues,
  lastUpdated: null,
  sessionMessages: new Map<string, Message[]>(),
  urlSessionInitialized: false,
  user: null
};
