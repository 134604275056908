import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import enTranslation from './locales/en.json';
import trTranslation from './locales/tr.json';

i18n.use(initReactI18next).init({
  // Set the default language
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false // React already escapes the content
  },
  lng: 'en',
  resources: {
    en: {
      translation: enTranslation
    },
    tr: {
      translation: trTranslation
    }
  }
});

export default i18n;
