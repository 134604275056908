import { ThemeProvider as MuiThemeProvider, ThemeOptions, createTheme } from '@mui/material/styles';
import { componentsOverrides } from './overrides';
import { createContrast } from './options/contrast';
import { createPresets } from './options/presets';
import { customShadows } from './custom-shadows';
import { palette } from './palette';
import { shadows } from './shadows';
import { typography } from './typography';
import { useMemo } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import merge from 'lodash/merge';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function ThemeProvider({ children }: Props) {
  const presets = createPresets('default');
  const contrast = createContrast('default', 'light');

  const memoizedValue = useMemo(
    () => ({
      customShadows: {
        ...customShadows('light'),
        ...presets.customShadows
      },
      direction: 'ltr',
      palette: {
        ...palette('light'),
        ...presets.palette,
        ...contrast.palette
      },
      shadows: shadows('light'),
      shape: { borderRadius: 8 },
      typography
    }),
    [presets.palette, presets.customShadows, contrast.palette]
  );

  const theme = createTheme(memoizedValue as ThemeOptions);

  theme.components = merge(componentsOverrides(theme), contrast.components);

  const themeWithLocale = useMemo(() => createTheme(theme), [theme]);

  return (
    <MuiThemeProvider theme={themeWithLocale}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
}
