import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ProviderFilter, initialProviderFilter, initialProviderFilterState } from './types';

const providerFilterSlice = createSlice({
  initialState: initialProviderFilterState,
  name: 'providerFilter',
  reducers: {
    clearFilter: (draft) => {
      draft.providerFilter = initialProviderFilter;
    },
    removeAvailableForMatch: (draft) => {
      draft.providerFilter.availableForMatch = initialProviderFilter.availableForMatch;
    },
    removeEmail: (draft) => {
      draft.providerFilter.email = initialProviderFilter.email;
    },
    removeExpertise: (draft, action: PayloadAction<number>) => {
      draft.providerFilter.expertise.splice(action.payload, 1);
    },
    removeFirstName: (draft) => {
      draft.providerFilter.firstname = initialProviderFilter.firstname;
    },
    removeLastName: (draft) => {
      draft.providerFilter.lastname = initialProviderFilter.lastname;
    },
    removeMethodology: (draft, action: PayloadAction<number>) => {
      draft.providerFilter.methodology.splice(action.payload, 1);
    },
    removePhone: (draft) => {
      draft.providerFilter.phone = initialProviderFilter.phone;
    },
    removePricingGroupAlias: (draft, action: PayloadAction<number>) => {
      draft.providerFilter.pricingGroupAlias.splice(action.payload, 1);
    },
    removeUserState: (draft, action: PayloadAction<number>) => {
      draft.providerFilter.userState.splice(action.payload, 1);
    },
    removeUserType: (draft, action: PayloadAction<number>) => {
      draft.providerFilter.userType.splice(action.payload, 1);
    },
    updateFilter: (draft, action: PayloadAction<ProviderFilter>) => {
      draft.providerFilter = { ...initialProviderFilter, ...action.payload };
    }
  }
});

export default providerFilterSlice.reducer;
export const {
  clearFilter,
  removeAvailableForMatch,
  removeEmail,
  removeExpertise,
  removeFirstName,
  removeLastName,
  removeMethodology,
  removePhone,
  removePricingGroupAlias,
  removeUserState,
  removeUserType,
  updateFilter
} = providerFilterSlice.actions;
