import { configureStore } from '@reduxjs/toolkit';
import { enableMapSet } from 'immer';
import rootReducer from './rootReducer';

enableMapSet();

const initialState = {};
const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }),
  preloadedState: initialState,
  reducer: rootReducer
});
export type RootState = ReturnType<typeof store.getState>;
if (import.meta.env.VITE_NODE_ENV === 'development' && import.meta.hot) {
  import.meta.hot.accept('./rootReducer', () => {
    // eslint-disable-next-line global-require
    const newRootReducer = require('./rootReducer').default;
    store.replaceReducer(newRootReducer);
  });
}

export default store;
