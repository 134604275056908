import { SponsorshipType } from 'src/views/client/list/types';

export interface ClientFilter {
  email: string;
  firstname: string;
  id: string;
  lastname: string;
  planAlias: string;
  planType: SponsorshipType;
  sponsorId: string;
  sponsorshipId: string;
  userState: string[];
}

export const initialClientFilter: ClientFilter = {
  email: '',
  firstname: '',
  id: '',
  lastname: '',
  planAlias: '',
  planType: '',
  sponsorId: '',
  sponsorshipId: '',
  userState: []
};

export interface ClientFilterState {
  clientFilter: ClientFilter;
}

export const initialClientFilterState: ClientFilterState = {
  clientFilter: initialClientFilter
};
