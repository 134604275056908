import { UserIssueGroup } from '../types';
import { fetchMessagingSessions, setAuthenticatedUser } from './slice';
import { selectMessagingRepo } from './selector';
import { setIssues, updateReceivedFromServer } from '../messageSessions/slice';
import { useAuthContext } from 'src/auth/hooks';
import { useEffect } from 'react';
import { useTypedDispatch, useTypedSelector } from 'src/hooks';
import Connector from './signalrClient';

type Props = {
  children: React.ReactNode;
};

export default function MessagingRepositoryProvider({ children }: Props) {
  const { events } = Connector();
  const dispatch = useTypedDispatch();
  const { issueGroups } = useTypedSelector(selectMessagingRepo);
  const { authenticated, user } = useAuthContext();

  useEffect(() => {
    if (authenticated) {
      dispatch(fetchMessagingSessions());
      dispatch(setAuthenticatedUser(user));
    } else {
      dispatch(setAuthenticatedUser(null));
    }
  }, [dispatch, authenticated, user]);

  useEffect(() => {
    dispatch(setIssues(issueGroups));
  }, [issueGroups, dispatch]);

  useEffect(() => {
    if (events != null) {
      const handleSessionUpdated = async (issueGroup: UserIssueGroup) => {
        dispatch(updateReceivedFromServer(issueGroup));
      };
      events(handleSessionUpdated);
    }
  }, [dispatch, events]);
  return <div>{children}</div>;
}
