import { GuestGuard } from 'src/auth/guard';
import { Outlet } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import AuthLayout from 'src/layouts/AuthLayout';
import Redirecting from '../components/redirecting';

const AuthPage = lazy(() => import('src/views/auth/AuthPage'));

export const loginRoutes = [
  {
    children: [
      {
        element: (
          <GuestGuard>
            <AuthLayout>
              <AuthPage />
            </AuthLayout>
          </GuestGuard>
        ),
        path: 'login'
      }
    ],
    element: (
      <Suspense fallback={<Redirecting />}>
        <Outlet />
      </Suspense>
    )
  }
];
