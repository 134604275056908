import { AuthUserType } from 'src/auth/types';
import { Message, UserIssueGroup, initialMessagingRepoState } from '../types';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isAfter } from 'date-fns';
import axios, { endpoints } from 'src/utils/axios';

export const fetchMessagingSessions = createAsyncThunk('issueRepo/fetchSessions', async () => {
  const response = await axios.get<UserIssueGroup[]>(endpoints.issues.getUserIssueGroups);
  return response.data;
});

export const fetchActiveSessionById = createAsyncThunk(
  'issueRepo/fetchActiveSessionById',
  async (sessionId: string) => {
    const response = await axios.get<UserIssueGroup[]>(
      endpoints.issues.getUserIssueGroupById(sessionId)
    );

    if (response.data.length === 0) {
      return null;
    }

    return response.data[0];
  }
);

export const fetchIssueForChatSession = createAsyncThunk(
  'issueRepo/fetchSession',
  async (sessionId: string) => {
    const response = await axios.get<UserIssueGroup[]>(
      endpoints.issues.getUserIssueGroup(sessionId)
    );
    return response.data?.[0];
  }
);

export const getMessages = createAsyncThunk(
  'messagesRepo/getMessages',
  async ({ limit, sessionId }: { limit: Number; sessionId: string }) => {
    const response = await axios.get<Message[]>(endpoints.issues.getMessages(sessionId, limit));
    const apiMessages = response.data;

    const messageIdsWithAttachment = apiMessages
      .filter((message) => message.attachments.length > 0)
      .map((i) => i.id);

    const messagesWithAttachment = await Promise.all(
      messageIdsWithAttachment.map(
        async (id) => (await axios.get<Message>(endpoints.issues.getMessage(id))).data
      )
    );

    const sorted = apiMessages
      .map((message) => {
        const messageWithAttachment = messagesWithAttachment.find((msg) => msg.id === message.id);

        return messageWithAttachment ?? message;
      })
      .sort((a, b) => (isAfter(new Date(a.dateReceived), new Date(b.dateReceived)) ? 1 : -1));

    return { sessionId, sorted };
  }
);

export const getMessage = createAsyncThunk('messagesRepo/getMessage', async (messageId: string) => {
  const response = await axios.get<Message>(endpoints.issues.getMessage(messageId));
  return response.data;
});

const messagingRepoSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(fetchMessagingSessions.fulfilled, (state, action) => {
        messagingRepoSlice.caseReducers.loadedSessions(state, action);
      })
      .addCase(fetchActiveSessionById.fulfilled, (state, action) => {
        messagingRepoSlice.caseReducers.issueUpdated(state, action);
      })
      .addCase(fetchIssueForChatSession.fulfilled, (state, action) => {
        messagingRepoSlice.caseReducers.issueUpdated(state, action);
      });
  },
  initialState: initialMessagingRepoState,
  name: 'messagesRepo',
  reducers: {
    issueUpdated: (draft, action: PayloadAction<UserIssueGroup>) => {
      if (action.payload) {
        const updatedList = draft.issueGroups;
        const index = updatedList.findIndex(
          (issue) => issue.chatSessionId === action.payload.chatSession?.id
        );
        if (index === -1) {
          updatedList.push(action.payload);
        } else {
          updatedList[index] = action.payload;
        }
        updatedList.sort((a, b) =>
          isAfter(new Date(a.lastUpdatedAt), new Date(b.lastUpdatedAt)) ? -1 : 1
        );
        draft.issueGroups = updatedList;
        draft.isFetching = false;
      }
    },
    loadedMessages: (draft, action: PayloadAction<{ sessionId: string; sorted: Message[] }>) => {
      const messages = action.payload.sorted;
      const existingMessages = draft.sessionMessages.get(action.payload.sessionId);
      if (existingMessages != null) {
        const missingMessages = messages.filter(
          (message) =>
            !existingMessages.some((existingMessage) => existingMessage.id === message.id)
        );
        existingMessages.push(...missingMessages);
        existingMessages.sort((a, b) =>
          isAfter(new Date(a.dateReceived), new Date(b.dateReceived)) ? 1 : -1
        );
      } else {
        draft.sessionMessages.set(action.payload.sessionId, messages);
      }
    },
    loadedSessions: (draft, action: PayloadAction<UserIssueGroup[]>) => {
      draft.issueGroups = action.payload;
      draft.isFetching = false;
    },
    setAuthenticatedUser: (draft, action: PayloadAction<AuthUserType>) => {
      draft.user = action.payload;
    },
    setUrlFilterInitialized: (draft, action: PayloadAction<boolean>) => {
      draft.urlSessionInitialized = action.payload;
    }
  }
});
export default messagingRepoSlice.reducer;
export const {
  issueUpdated,
  loadedMessages,
  loadedSessions,
  setAuthenticatedUser,
  setUrlFilterInitialized
} = messagingRepoSlice.actions;
