export interface SessionState {
  loading: boolean;
  settings: Settings;
  user: AuthUser;
}

export interface Settings {
  language: string;
}

export type AuthUser = Record<string, any>;
export const initialSessionState: SessionState = {
  loading: true,
  settings: {
    language: 'en'
  },
  user: null
};
