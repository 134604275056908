import { SwitchProps, switchClasses } from '@mui/material/Switch';
import { Theme, alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export function switches(theme: Theme) {
  const lightMode = theme.palette.mode === 'light';

  const rootStyles = (ownerState: SwitchProps) => {
    const { color } = ownerState;

    return {
      height: 38,
      padding: '9px 13px 9px 12px',
      width: 58,
      [`& .${switchClasses.thumb}`]: {
        boxShadow: 'none',
        color: theme.palette.common.white,
        height: 14,
        width: 14
      },
      [`& .${switchClasses.track}`]: {
        backgroundColor: alpha(theme.palette.grey[500], 0.48),
        borderRadius: 14,
        opacity: 1
      },
      [`& .${switchClasses.switchBase}`]: {
        left: 3,
        padding: 12,
        [`&.${switchClasses.checked}`]: {
          transform: 'translateX(13px)',
          [`& .${switchClasses.thumb}`]: {
            ...(color === 'default' &&
              !lightMode && {
                color: theme.palette.grey[800]
              })
          },
          [`&+.${switchClasses.track}`]: {
            opacity: 1,
            ...(color === 'default' && {
              backgroundColor: theme.palette.text.primary
            })
          }
        },
        [`&.${switchClasses.disabled}`]: {
          [`& .${switchClasses.thumb}`]: {
            opacity: lightMode ? 1 : 0.48
          },
          [`&+.${switchClasses.track}`]: {
            opacity: 0.48
          }
        }
      },
      // Small
      [`&.${switchClasses.sizeSmall}`]: {
        height: 24,
        padding: '4px 8px 4px 7px',
        width: 40,
        [`& .${switchClasses.thumb}`]: {
          height: 10,
          width: 10
        },
        [`& .${switchClasses.switchBase}`]: {
          padding: 7,
          [`&.${switchClasses.checked}`]: {
            transform: 'translateX(9px)'
          }
        }
      }
    };
  };

  return {
    MuiSwitch: {
      styleOverrides: {
        root: ({ ownerState }: { ownerState: SwitchProps }) => rootStyles(ownerState)
      }
    }
  };
}
