import { ActionMapType, AuthStateType, AuthUserType, TwoFactorSetupType } from 'src/auth/types';

export enum LoginReducerTypes {
  INITIAL = 'INITIAL',
  LOGOUT = 'LOGOUT',
  REGISTER = 'REGISTER',
  TWO_FACTOR_AUTH_SUCCESS = 'TWO_FACTOR_AUTH_SUCCESS',
  USER_CREDENTIALS_SUCCESS = 'USER_CREDENTIALS_SUCCESS'
}

type Payload = {
  [LoginReducerTypes.INITIAL]: {
    user: AuthUserType;
  };
  [LoginReducerTypes.LOGOUT]: undefined;
  [LoginReducerTypes.USER_CREDENTIALS_SUCCESS]: {
    twoFactorSetup: TwoFactorSetupType;
    user: AuthUserType;
  };
  [LoginReducerTypes.TWO_FACTOR_AUTH_SUCCESS]: {
    user: AuthUserType;
  };
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

export const loginReducerInitialState: AuthStateType = {
  loading: true,
  step: 'login',
  twoFactorSetup: null,
  user: null
};

export const loginReducer = (state: AuthStateType, action: ActionsType): AuthStateType => {
  if (action.type === LoginReducerTypes.INITIAL) {
    return {
      ...state,
      loading: false,
      user: action.payload.user
    };
  }
  if (action.type === LoginReducerTypes.LOGOUT) {
    return {
      ...state,
      step: 'login',
      user: null
    };
  }
  if (action.type === LoginReducerTypes.USER_CREDENTIALS_SUCCESS) {
    return {
      ...state,
      step: '2fa',
      twoFactorSetup: action.payload.twoFactorSetup,
      user: action.payload.user
    };
  }
  if (action.type === LoginReducerTypes.TWO_FACTOR_AUTH_SUCCESS) {
    return {
      ...state,
      user: action.payload.user
    };
  }
  return state;
};
